import {BaseService} from  "./base.service";
import { Like } from '../models/like';


export class LikeService extends BaseService
{
    protected PRIMARY_RESOURCE = "likes/";


    addUserLike(userTweetId: number) : Promise<Like>
    {
        return this.post(this.PRIMARY_RESOURCE + "userTweets/" + userTweetId)
    }

    addProductLike(productId: number) : Promise<Like>
    {
        return this.post(this.PRIMARY_RESOURCE + "products/" + productId)
    }


    deleteProductLikeByProductId(productId: number) : Promise<any>
    {
        return this.delete(this.PRIMARY_RESOURCE + "products/" + productId);
    }

    deleteLike(id: number) : Promise<any>
    {
        return this.delete(this.PRIMARY_RESOURCE +  id);
    }


}