import { Product } from '../models/product';
import { EditorState, ContentState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';




export function findNode(array, valueToMatch: any, propertyKey: string = 'id', childrenKey: string = 'children') {

  for (const node of array) {



    if (node[propertyKey] == valueToMatch) 
    {
      return node;
    }
    

    if (node.children.length > 0 ) {

      const child = findNode(node[childrenKey], valueToMatch, propertyKey, childrenKey );

      if (child) 
      {
        return child;
      }
    }

  }
}

export function findNodeAddParents(array, valueToMatch: any, propertyKey: string = 'id', childrenKey: string = 'children', parents = []) {

  for (const node of array) {

    if(!node.parentId)
    {
      parents = [];
    }

    if (node[propertyKey] == valueToMatch) 
    {
      node.parents = parents;
      return node;
    }
    

    if (node.children.length > 0 ) {

      parents.push(node);

      const child = findNodeAddParents(node[childrenKey], valueToMatch, propertyKey, childrenKey, parents );

      if (child) 
      {
        child.parents = parents;
        return child;
      }
    }

  }
}

// export function findNode(array, valueToMatch: any, propertyKey: string = 'id', childrenKey: string = 'children', parents = []) {
//   if (!array) { return; }

//   for (const item of array) {
//     // Test current object
//     if (item[propertyKey] === valueToMatch) { 
//       item.parents = parents;
//       return item; 
//     }


//     if (item[childrenKey]) {
//       parents.push(item);
//     }

//     // Test children recursively
//     const child = findNode(item[childrenKey], childrenKey, propertyKey, valueToMatch, parents);
//     if (child) { return child; }
//   }
// }

export function shorten(str, maxLen, separator = ' ') {

  if (!str?.length) {
    return str;
  }

  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}



export function generateId(length: number = 12): string {
  var result = '';
  var characters = '1234567890';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function getFileFromUrl(url: string)
{
  let fileName = url.split('/').pop();

  let response = await fetch(url);
    const contentType = response.headers.get('Content-Type');
    const blob = await response.blob();
    const file = new File([blob], fileName, {type: contentType });

  return file;
}


export function reOrder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


export function toSeoUrl(url) {

  if (!url) {
    return "";
  }

  url = url.toString()               // Convert to string
    .normalize('NFD')               // Change diacritics
    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+/g, '-')            // Change whitespace to dashes
    .toLowerCase()                  // Change to lowercase
    .replace(/&/g, '-and-')          // Replace ampersand
    .replace(/[^a-z0-9\-_]/g, '')     // Remove anything that is not a letter, number or dash
    .replace(/-+/g, '-')             // Remove duplicate dashes
    .replace(/^-*/, '')              // Remove starting dashes
    .replace(/-*$/, '');             // Remove trailing dashes

    if(!url)
    {
      url = "param";
    }

  return url;
}

export function getUserProfileUrl(username: string) {
  return "/" + toSeoUrl(username);
}

export function getProductDetailUrl(username: string, product: Product) {
  return "/" + toSeoUrl(username) + "/" + toSeoUrl(product?.name) + "/" + product?.id;
}

export function getProductUrl(product: Product) {
  return "/" + toSeoUrl(product.publicUserResponse.username) + "/" + toSeoUrl(product?.name) + "/" + product?.id;
}

export function getProductDetailFullUrl(username: string, product: Product): string {
  return process.env.REACT_APP_BASE_URL + toSeoUrl(username) + "/" + toSeoUrl(product?.name) + "/" + product?.id;
}

export function getUserStoreFullUrl(username: string) {
    return process.env.REACT_APP_BASE_URL + toSeoUrl(username);
}

export function getUserStoreUrl(username: string, productCategoryId?: number) {
  if (productCategoryId) {
    return "/" + toSeoUrl(username) + "/" + productCategoryId;
  }
  else {
    return "/" + toSeoUrl(username);
  }

}

function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height){
  var doRatio = width / height;
  var cRatio = containerWidth / containerHeight;
  var targetWidth = 0;
  var targetHeight = 0;
  var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

  if (test) {
      targetWidth = containerWidth;
      targetHeight = targetWidth / doRatio;
  } else {
      targetHeight = containerHeight;
      targetWidth = targetHeight * doRatio;
  }

  return {
      width: targetWidth,
      height: targetHeight,
      x: (containerWidth - targetWidth) / 2,
      y: (containerHeight - targetHeight) / 2
  };
}


export function formatMoney(number: number, currencyCode: string = "USD"): string {

  let result = "";

  if (number) {
    if (currencyCode == "LBP") {
      result = (number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " L.L";

    }
    else if (currencyCode == "USD") {
      result = "$" + (number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else {
      result = (number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  return result;
}


export function createReadOnlyEditor(value: string, truncateCount: number = 0): any {
  if (value) {
    let valueParsed = JSON.parse(value);
    let valueEditorContent = stateFromHTML(valueParsed);

    if (truncateCount > 0) {
      return truncateReadOnlyEditor(EditorState.createWithContent(valueEditorContent), truncateCount);

    }
    else {
      return EditorState.createWithContent(valueEditorContent);
    }
  }
  else {
    return EditorState.createEmpty();
  }
}


function truncateReadOnlyEditor(editorState, charCount = 200) {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();

  let index = 0;
  let currentLength = 0;
  let isTruncated = false;
  const truncatedBlocks = [];

  while (!isTruncated && blocks[index]) {
    const block = blocks[index];
    const length = block.getLength();
    if (currentLength + length > charCount) {
      isTruncated = true;
      const truncatedText = block
        .getText()
        .slice(0, charCount - currentLength);
      const state = ContentState.createFromText(`${truncatedText}...`);
      truncatedBlocks.push(state.getFirstBlock());
    } else {
      truncatedBlocks.push(block);
    }
    currentLength += length + 1;
    index++;
  }

  if (isTruncated) {
    const state = ContentState.createFromBlockArray(truncatedBlocks);
    return EditorState.createWithContent(state);
  }

  return editorState;
};
