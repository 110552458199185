import React from "react";

export default class ViewportBlockSection extends React.Component<any, any>  {

    render() {

        return (
            <div></div>
        );

    }

}