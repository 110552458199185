import React from "react";

interface IProps {
    id?: any;
    title?: string;
    message?: string;
    buttonClass?: string;
    buttonIcon?: any;
    buttonText?: string;
    onConfirm?: any;
    confimText?: string;
}

interface IState {
    id?: any;
    title: string;
    message: string;
    buttonClass?: string;
    buttonIcon?: any;
    buttonText?: string;
    onConfirm?: any;
    confimText?: string;
}


export default class ConfirmDialog extends React.Component<IProps, IState> {

    state: IState =
        {
            title: '',
            message: '',
            onConfirm: null,
            confimText: ''
        }

    constructor(props: IProps) {
        super(props);
    }

    async componentDidMount() {

        this.setState({
            id: this.props.id ?? '',
            title: this.props.title ?? 'Confirm',
            message: this.props.message ?? 'Are you sure?',
            buttonClass: this.props.buttonClass ?? '',
            buttonIcon: this.props.buttonIcon ?? null,
            buttonText: this.props.buttonText ?? '',
            onConfirm: this.props.onConfirm,
            confimText: this.props.confimText ?? 'Confirm',
        });
    }


    confirmed() {
        if (typeof this.state.onConfirm == "function") {
            this.state.onConfirm();
        }
    }



    render() {

        return (
            <span>
                <button type="button" className={"btn " + this.state.buttonClass} data-bs-toggle="modal"
                    data-bs-target={"#exampleModal" + this.state.id}>
                    {
                        this.state.buttonIcon &&
                        <i className={"bi bi-" + this.state.buttonIcon}></i>
                    }
                    {
                        this.state.buttonText &&
                        <span>
                            {this.state.buttonText}
                        </span>
                    }
                </button>

                <div className="modal fade" id={"exampleModal" + this.state.id} tabIndex={-1}
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {this.state.title}
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div style={{"whiteSpace": "pre-wrap"}} className="modal-body">
                                <div style={{fontSize: "1em", fontWeight: 400, lineHeight: "normal"}}  dangerouslySetInnerHTML={{ __html: this.state.message }}></div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.confirmed()}>{this.state.confimText}</button>
                                <button type="button" className="btn btn-third" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        )
    }
}

