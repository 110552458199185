import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  RouteProps,
  RouteComponentProps
} from "react-router-dom";
import "./UserTweetCard.scss"
import { UserTweet } from '../../models/user-tweet';
import { AppContext } from '../../lib/app-context';
import { LikeService } from '../../services/like.service';
import { UserTweetService } from '../../services/user-tweet.service';
import ConfirmDialog from '../confirm-dialog/ConfirmDialog';
import { getUserProfileUrl } from "../../lib/common-helper";

interface IProps {
  userTweet: UserTweet;
  canGoToUserTweet?: boolean;
}

interface IState {
  userTweet: UserTweet;
  commentBoxOpen: boolean;
  defaultImage: string;
  tweet: string;
  newUserTweet: UserTweet;
  canGoToUserTweet: boolean;
}


export default class UserTweetCard extends React.Component<IProps, IState> {

  static contextType = AppContext;

  likeService: LikeService = new LikeService();
  userTweetService: UserTweetService = new UserTweetService();

  state: IState =
    {
      userTweet: null,
      commentBoxOpen: false,
      defaultImage: '',
      tweet: '',
      newUserTweet: null,
      canGoToUserTweet: true
    }

  constructor(props: IProps) {
    super(props);
  }

  async componentDidMount() {

    let userTweet = this.props.userTweet;
    let canGoToUserTweet = this.props.canGoToUserTweet ?? true;

    this.init(userTweet, canGoToUserTweet);
  }

  async init(userTweet: UserTweet, canGoToUserTweet: boolean) {
    this.setState({ userTweet: userTweet });
    this.setState({ canGoToUserTweet: canGoToUserTweet });
  }

  async componentWillReceiveProps(nextProps) {

    if (this.props.userTweet != nextProps.userTweet) {

      let userTweet = nextProps.userTweet;
      let canGoToUserTweet = nextProps.canGoToUserTweet ?? true;

      this.init(userTweet, canGoToUserTweet);
    }
  }


  addUserLike(userTweetId: number) {
    this.likeService.addUserLike(userTweetId).then(like => {

      this.setState(
        {
          userTweet: {
            ...this.state.userTweet,
            likes: [...this.state.userTweet.likes, like]
          }
        });

    });
  }

  deleteUsertLike(likeId: number) {

    this.likeService.deleteLike(likeId).then(y => {

      this.setState(
        {
          userTweet: {
            ...this.state.userTweet,
            likes: this.state.userTweet.likes.filter(x => x.id != likeId)
          }
        });

    });
  }

  postUserTweet() {

    this.userTweetService.replyToUserTweet(this.state.userTweet.id, this.state.tweet).then(userTweet => {

      this.setState({ newUserTweet: userTweet });
      this.setState({ tweet: '' });
      this.setState({ commentBoxOpen: false });

      this.setState(
        {
          userTweet: {
            ...this.state.userTweet,
            children: [...this.state.userTweet.children, userTweet]
          }
        });

    });

  }


  deleteUserTweet(id: number) {
    if (id) {
      this.userTweetService.removeUserTweet(id).then(x => {

        this.setState({ userTweet: null });

      });
    }

  }



  render() {

    return (
      this.state.userTweet &&
      <div>
        <div className={"card-wrapper mb-2 p-2 " + (this.state.canGoToUserTweet == true ? ' ' : 'card-wrapper-bottom-border ')} >
          <div className="d-flex">
            <Link to={getUserProfileUrl(this.state.userTweet.publicUserResponse?.username)} className="me-3" >
              <div >
                <img style={{ width: 45, height: 45 }} className="card-img-top img-circle rounded-circle"
                  src={this.state?.userTweet?.publicUserResponse?.avatarFullURL}
                  alt="Card image cap" />
              </div>
            </Link>

            <div className="d-flex flex-column flex-grow-1" >
              <div className="d-flex align-items-center ps-2">
                <Link className="card-user-name" to={getUserProfileUrl(this.state.userTweet.publicUserResponse?.username)} >
                  {this.state?.userTweet?.publicUserResponse?.name}
                </Link>
                &nbsp;
                <Link className="card-user-username grey" to={getUserProfileUrl(this.state.userTweet.publicUserResponse?.username)} >
                  @{this.state?.userTweet?.publicUserResponse?.username}
                </Link>

                {
                  this.context.currentUser?.username == this.state.userTweet.publicUserResponse.username &&
                  <div className="ms-auto">
                    <ConfirmDialog id={this.state.userTweet.id} buttonIcon="trash"
                      buttonClass="btn-danger" onConfirm={() => this.deleteUserTweet(this.state.userTweet.id)} />
                  </div>

                }


              </div>

              <div className="mt-1">

                {
                  this.state.canGoToUserTweet &&
                  <Link className="ps-2 mt-1 mb-1 pt-2 pb-2 card-user-tweet-link d-block text-decoration-none text-body" to={"/userPost/" + this.state.userTweet.id} >
                    {this.state.userTweet.tweet}
                  </Link>
                }

                {
                  !this.state.canGoToUserTweet &&
                  <div className="ps-2 mt-1 mb-1 pt-2 pb-2">
                    {this.state.userTweet.tweet}
                  </div>

                }


              </div>
              <div className="d-flex justify-content-between">


                <div className="d-flex align-items-center">


                  {
                    // user is not logged in, dont show button
                    !this.context.currentUser?.username &&
                    <div>
                      <i className="bi bi-heart me-2"></i>
                      <span>
                        {this.state.userTweet.likes?.length}
                      </span>
                    </div>
                  }

                  {
                    // use is logged in
                    this.context.currentUser?.username &&
                    <div>
                      {
                        this.state.userTweet.likes?.length > 0 &&
                        this.state.userTweet.likes.find(x => x.publicUserResponse?.username == this.context.currentUser?.username) &&
                        <button type="button" className="btn" style={{ color: "#E0245E" }}
                          onClick={() => this.deleteUsertLike(this.state.userTweet.likes.find(x => x.publicUserResponse?.username == this.context.currentUser?.username).id)}>
                          <i className="bi bi-heart-fill me-2"></i>
                          <span>
                            {this.state.userTweet.likes?.length}
                          </span>
                        </button>
                      }
                      {
                        (
                          !(this.state.userTweet.likes?.length > 0) ||
                          !(this.state.userTweet.likes.find(x => x.publicUserResponse?.username == this.context.currentUser?.username))) &&
                        <button className="btn" type="button" onClick={() => this.addUserLike(this.state.userTweet.id)}>
                          <i className="bi bi-heart me-2"></i>
                          <span>
                            {this.state.userTweet.likes?.length}
                          </span>
                        </button>
                      }
                    </div>

                  }

                </div>

                <div className="d-flex align-items-center">


                  {
                    // user is not logged in, dont show button
                    !this.context.currentUser?.username &&
                    <div>
                      <i className="bi bi-chat me-2"></i>
                      <span>
                        {this.state.userTweet.children?.length}
                      </span>
                    </div>
                  }

                  {
                    // use is logged in
                    this.context.currentUser?.username &&
                    <button className="btn" type="button" onClick={() => this.setState({ commentBoxOpen: !this.state.commentBoxOpen })} >
                      <i className="bi bi-chat me-2"></i>
                      <span>
                        {this.state.userTweet.children?.length}
                      </span>
                    </button>
                  }


                </div>


              </div>
            </div>

          </div>

        </div>
        {
          this.state.commentBoxOpen &&
          <div className="product-card-footer p-2 mb-4">
            <div className="d-flex">

              {
                this.context?.currentUser?.avatarFullURL &&
                <img className="img-circle rounded-circle"
                  height={35}
                  width={35}
                  src={this.context.currentUser.avatarFullURL}
                  alt="Account" />
              }
              {

                this.context?.currentUser?.userId &&
                !this.context?.currentUser?.avatarFullURL &&
                this.state.defaultImage &&

                <img className="img-circle rounded-circle"
                  height={35}
                  width={35}
                  src={this.state.defaultImage}
                  alt="Avatar" />
              }

              <input className="form-control ms-2 me-2" type="text"
                value={this.state.tweet}
                style={{ border: "none", resize: "none" }}
                onChange={(event) => this.setState({ tweet: event.target.value })}
                placeholder="Write a comment" aria-label="Write a comment" />

              <div className="ms-auto">
                <button className="btn btn-primary" disabled={this.state.tweet == ''} onClick={() => this.postUserTweet()}>
                  Post
                </button>
              </div>

            </div>
          </div>
        }

        {
          this.state.newUserTweet &&
          <div className="product-card-footer p-2">
            <UserTweetCard userTweet={this.state.newUserTweet} />
          </div>
        }


      </div>
    )
  }
}
