import { ChangePasswordRequest } from "../models/request/change-password.request";
import { PublicUserResponse } from "../models/response/public-user.response";
import { UserProductResponse } from "../models/response/user-product.response";
import {BaseService} from  "./base.service";
import { ProfilePageResponse } from '../models/response/profile-page-response';
import { StoreDiscoveryResponse } from "../models/response/store-discovery-response";
import { UpdateBusinessUserRequest } from '../models/request/update-business-user.request';


export class UserService extends BaseService
{
    protected PRIMARY_RESOURCE = "users/";


    verifyUser(verificationCode: string) : Promise<PublicUserResponse>
    {
        return this.put(this.PRIMARY_RESOURCE + "verify/" + verificationCode);
    }

    getPublicUser(username: string) : Promise<PublicUserResponse>
    {
        return this.get(this.PRIMARY_RESOURCE + username);
    }

    
    profilePage(username: string) : Promise<ProfilePageResponse>
    {
        return this.get(this.PRIMARY_RESOURCE + username + "/profile");
    }

    
    geStoreDiscoveryPage() : Promise<StoreDiscoveryResponse>
    {
        return this.get(this.PRIMARY_RESOURCE + "storeDiscovery");
    }

    
    userProducts(username: string) : Promise<UserProductResponse>
    {
        return this.get(this.PRIMARY_RESOURCE + username + "/userProducts");
    }

    

    searchUsers(searchText: string)
    {
        return this.get( this.PRIMARY_RESOURCE + "search/" + searchText);
    }

    deleteCurrentUser()
    {
        return this.delete( this.PRIMARY_RESOURCE );
    }

    changePassword(changePasswordRequest: ChangePasswordRequest )
    {
        return this.put(this.PRIMARY_RESOURCE + "changePassword" , changePasswordRequest, false);
    }

    updateBusinessUser(updateBusinessUserRequest: UpdateBusinessUserRequest)
    {
        return this.put(this.PRIMARY_RESOURCE + "businessUser", updateBusinessUserRequest );
    }
    


    
    latestUsers() : Promise<PublicUserResponse[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "latest/");
    }

}