import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Product } from '../../models/product';
import "./ProductCard.scss"
import { AppContext } from '../../lib/app-context';
import { LikeService } from '../../services/like.service';
import { UserTweetService } from '../../services/user-tweet.service';
import { UserTweet } from '../../models/user-tweet';
import UserTweetCard from '../user-tweet-card/UserTweetCard';
import ProductCardImage from '../product-card-image/ProductCardImage';
import { formatMoney, getProductDetailUrl, getUserStoreUrl, createReadOnlyEditor, getProductDetailFullUrl } from '../../lib/common-helper';
import { convertFromRaw, EditorState, Editor } from 'draft-js';
import { RichEditor } from "../rich-editor/RichEditor";
import { RichEditorDisplay } from '../rich-editor-display/RichEditorDisplay';
import { ProductStatusEnum, UserLevelEnum } from "../../lib/enums";

interface IProps {
  product: Product;
}

interface IState {
  product: Product;
  commentBoxOpen: boolean;
  defaultImage: string;
  tweet: string;
  newUserTweet: UserTweet;
}


export default class ProductCard extends React.Component<IProps, IState> {

  likeService: LikeService = new LikeService();
  userTweetService: UserTweetService = new UserTweetService();

  static contextType = AppContext;

  state: IState =
    {
      product: null,
      commentBoxOpen: false,
      defaultImage: '',
      tweet: '',
      newUserTweet: null,
    }

  // shorten(str, maxLen, separator = ' ') {
  //   if (str.length <= maxLen) return str;
  //   return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
  // }

  constructor(props: IProps) {
    super(props);
  }

  async componentDidMount() {

    this.setState({ product: this.props.product });
    this.setState({ defaultImage: process.env.REACT_APP_DEFAULT_USER_IMAGE });

    // user logged in => open comment box
    if (this.context.currentUser?.username) {
      this.setState({ commentBoxOpen: true });
    }

  }

  getWhatsAppUrl(): string {
    let productTextEncoded: string = encodeURI('Hello, am interested in this item \n\r' +
      getProductDetailFullUrl(this.state.product.publicUserResponse.username, this.state.product)
    );

    return "https://wa.me/" + this.state.product.publicUserResponse.whatsapp + "?text=" + productTextEncoded
  }

  createEditor(shortDescription: string): any {
    if (shortDescription) {
      let shortDescriptionParsed = JSON.parse(shortDescription);
      let shortDescriptionEditorContent = convertFromRaw(shortDescriptionParsed);
      return EditorState.createWithContent(shortDescriptionEditorContent);
    }
    else {
      return EditorState.createEmpty();
    }
  }


  addProductLike(productId: number) {
    this.likeService.addProductLike(productId).then(productLike => {

      this.setState(
        {
          product: {
            ...this.state.product,
            likes: [...this.state.product.likes, productLike]
          }
        });

    });
  }

  deleteProductLike(likeId: number) {

    this.likeService.deleteLike(likeId).then(y => {

      this.setState(
        {
          product: {
            ...this.state.product,
            likes: this.state.product.likes.filter(x => x.id != likeId)
          }
        });

    });
  }


  postProductUserTweet() {

    this.userTweetService.createProductUserTweet(this.state.product.id, this.state.tweet).then(userTweet => {

      this.setState({ newUserTweet: userTweet });
      this.setState({ tweet: '' });
      this.setState({ commentBoxOpen: false });

      this.setState(
        {
          product: {
            ...this.state.product,
            userTweets: [...this.state.product.userTweets, userTweet]
          }
        });

    });

  }




  render() {

    return (
      this.state.product &&
      <div className="mb-4">
        <div className="card-wrapper">
          <div className="d-flex align-items-center ps-2 pe-2 pt-2 pb-2" >
            <Link to={getUserStoreUrl(this.state.product.publicUserResponse?.username)} className="me-2" >
              <div >
                <img style={{ width: 45, height: 45 }} className="card-img-top img-circle rounded-circle"
                  src={this.state?.product?.publicUserResponse?.avatarFullURL}
                  alt="Card image cap" />
              </div>
            </Link>
            <div>
              <Link className="card-user-name" to={getUserStoreUrl(this.state.product.publicUserResponse?.username)} >
                {this.state?.product?.publicUserResponse?.name}
              </Link>
              {/* &nbsp;
              <Link className="card-user-username" to={getUserStoreUrl(this.state.product.publicUserResponse?.username)} >
                @{this.state?.product?.publicUserResponse?.username}
              </Link> */}
            </div>

            {
              (this.state.product.publicUserResponse.username == this.context.currentUser?.username || this.state.product.publicUserResponse.whatsapp) &&

              <div className="ms-auto d-flex align-items-center justify-content-center flex-wrap">


                {/* Whatsapp Button  */}
                {
                  this.state.product.publicUserResponse.whatsapp && 
                  this.state.product.publicUserResponse?.userLevelId == UserLevelEnum.BusinessUser &&
                    <a target="_blank" style={{ textDecoration: "none", display: "inline-block" }}
                      className="product-card-order-button"
                      href={this.getWhatsAppUrl()}>

                      <button className="btn btn-primary btn-whatsapp-product-card-wrapper d-flex align-items-center" >

                        <i className="bi bi-whatsapp fs-5"></i>
                        {
                          this.state.product.productStatusId == ProductStatusEnum.OutOfStock &&
                          <span className="ps-2 d-none d-lg-block fs-7">
                            CONTACT
                          </span>
                        }
                        {
                          this.state.product.productStatusId != ProductStatusEnum.OutOfStock &&
                          <span className="ps-2 d-none d-lg-block fs-7">
                            ORDER
                          </span>
                        }


                      </button>
                    </a>
                }

                {
                  this.state.product.publicUserResponse.username == this.context.currentUser?.username &&
                  <Link className="btn btn-secondary ms-2" to={"/product/save/" + this.state.product.id}>
                    <i className="bi bi-pencil fs-6"></i>
                  </Link>
                }

              </div>

            }
          </div>
          <Link to={getProductDetailUrl(this.state?.product?.publicUserResponse.username, this.state?.product)} className="text-decoration-none text-dark" >

            <div className="">
              <div>
                {/* <img className="img-fluid" src={this.state.product.productImage?.imageFullURL} /> */}
                <ProductCardImage productImage={this.state.product.productImage} productImages={this.state.product.productImages} />
              </div>
              <div className="p-2 d-flex">
                <h3 className="me-2 ">{this.state.product?.name}</h3>
                <h3 className="ms-auto" >

                  {
                    this.state.product.productStatusId == ProductStatusEnum.OutOfStock &&
                    <span className="fs-6 p-1 nowrap red" style={{ border: "solid #e6061b 1px" }} >
                      OUT OF STOCK
                    </span>
                  }

                  {
                    this.state.product.primaryPrice &&
                    this.state.product.productStatusId == ProductStatusEnum.Enabled &&
                    <span>
                      {formatMoney(this.state.product.primaryPrice, this.state.product.primaryCurrency?.code)}
                    </span>
                  }




                </h3>
              </div>
              {
                this.state.product.shortDescription &&
                <div className="p-2 justify">
                  {
                    //shorten(this.getShortDescription(this.state.product.shortDescription), 500)
                    //  this.getShortDescription(this.state.product.shortDescription)


                    <RichEditorDisplay
                      editorState={createReadOnlyEditor(this.state.product.shortDescription, 300)}
                    />

                  }
                </div>
              }

            </div>
          </Link>
          <div className="mt-3 d-flex justify-content-between ps-2 pe-2 pb-2">

            <div className="d-flex align-items-center">

              {
                // user is not logged in, dont show button
                !this.context.currentUser?.username &&
                <div>
                  <i className="bi bi-heart me-2"></i>
                  <span>
                    {this.state.product.likes?.length}
                  </span>
                </div>
              }

              {
                // use is logged in
                this.context.currentUser?.username &&
                <div>
                  {
                    this.state.product.likes?.length > 0 &&
                    this.state.product.likes.find(x => x.publicUserResponse?.username == this.context.currentUser?.username) &&
                    <button type="button" className="btn" style={{ color: "#E0245E" }}
                      onClick={() => this.deleteProductLike(this.state.product.likes.find(x => x.publicUserResponse?.username == this.context.currentUser?.username).id)}>
                      <i className="bi bi-heart-fill me-2"></i>
                      <span>
                        {this.state.product.likes?.length}
                      </span>
                    </button>
                  }
                  {
                    (
                      !(this.state.product.likes?.length > 0) ||
                      !(this.state.product.likes.find(x => x.publicUserResponse?.username == this.context.currentUser?.username))) &&
                    <button className="btn" type="button" onClick={() => this.addProductLike(this.state.product.id)}>
                      <i className="bi bi-heart me-2"></i>
                      <span>
                        {this.state.product.likes?.length}
                      </span>
                    </button>
                  }
                </div>

              }

            </div>

            <div className="d-flex align-items-center">


              {
                // user is not logged in, dont show button
                !this.context.currentUser?.username &&
                <div>
                  <i className="bi bi-chat me-2"></i>
                  <span>
                    {this.state.product.userTweets?.length}
                  </span>
                </div>
              }

              {
                // use is logged in
                this.context.currentUser?.username &&
                <button className="btn" type="button" onClick={() => this.setState({ commentBoxOpen: !this.state.commentBoxOpen })} >
                  <i className="bi bi-chat me-2"></i>
                  <span>
                    {this.state.product.userTweets?.length}
                  </span>
                </button>
              }


            </div>
          </div>
        </div>
        {
          this.state.commentBoxOpen &&
          <div className="product-card-footer p-2">
            <div className="d-flex">

              {
                this.context?.currentUser?.avatarFullURL &&
                <img className="img-circle rounded-circle"
                  height={34}
                  src={this.context.currentUser.avatarFullURL}
                  alt="Account" />
              }
              {

                this.context?.currentUser?.userId &&
                !this.context?.currentUser?.avatarFullURL &&
                this.state.defaultImage &&

                <img className="img-circle rounded-circle"
                  height={34}
                  src={this.state.defaultImage}
                  alt="Avatar" />
              }

              <input className="form-control ms-2 me-2" type="text"
                value={this.state.tweet}
                style={{ border: "none", resize: "none" }}
                onChange={(event) => this.setState({ tweet: event.target.value })}
                placeholder="Write a comment" aria-label="Write a comment" />

              <div className="ms-auto">
                <button className="btn btn-primary" disabled={this.state.tweet == ''} onClick={() => this.postProductUserTweet()}>
                  Post
                </button>
              </div>

            </div>
          </div>
        }

        {
          this.state.newUserTweet &&
          <div className="product-card-footer p-2">
            <UserTweetCard userTweet={this.state.newUserTweet} />
          </div>
        }


      </div>
    )
  }
}
