import {BaseService} from  "./base.service";
import { Currency } from "../models/currency";
import { ContactUsRequest } from "../models/request/contact-us.request";
import { Country } from '../models/country';
import { StoreCategory } from '../models/store-category';
import { ShowqaceCategory } from "../models/showqace-category";


export class CommonService extends BaseService
{
    protected PRIMARY_RESOURCE = "common/";

    sendContactUs(contactUsRequest: ContactUsRequest) : Promise<any>
    {
        return this.post(this.PRIMARY_RESOURCE + "send/email", contactUsRequest);
    }


    
    getCountries() : Promise<Country[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "countries");
    }

    
    getShowqaceCategories() : Promise<ShowqaceCategory[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "showqaceCategories");
    }

    getShowqaceCategory(showqaceCategoryId: number) : Promise<ShowqaceCategory>
    {
        return this.get(this.PRIMARY_RESOURCE + "getShowqaceCategory/" + showqaceCategoryId);
    }

    getShowqaceProductCategoriesThatHasItems() : Promise<ShowqaceCategory[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "getShowqaceProductCategoriesThatHasItems");
    }
    
    

}