import { UserSessionResponse } from "../response/user-session.response";

export class AppContextModel
{
    isAuthenticated?: boolean;
    setIsAuthenticated: any;

    currentUser: UserSessionResponse;
    setCurrentUser: any;

}

