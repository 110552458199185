import axios, { Method } from 'axios';
import { toast } from 'react-toastify';

export class BaseService
{
    protected baseUrl = process.env.REACT_APP_BASE_API;

    protected get(url: string, data?: any, isAbsoluteURL?: boolean): Promise<any>
    {
      return this.makeRequest('GET', url, data, isAbsoluteURL);
    }
  
    protected post(url: string, data?: any,  showSuccess: boolean = true, isAbsoluteURL?: boolean): Promise<any>
    {
      return this.makeRequest('POST', url, data, showSuccess, isAbsoluteURL);
    }

    protected put(url: string, data?: any, showSuccess: boolean = true, isAbsoluteURL?: boolean): Promise<any>
    {
      return this.makeRequest('PUT', url, data, showSuccess, isAbsoluteURL);
    }

    protected delete(url: string, data?: any, showSuccess: boolean = true, isAbsoluteURL?: boolean): Promise<any>
    {
      return this.makeRequest('DELETE', url, data, showSuccess, isAbsoluteURL);
    }

  
    protected uploadPost(url: string, fileParam: any, data?: any, showSuccess: boolean = true,  isAbsoluteURL?: boolean)
    {
      return this.makeRequestUpload('POST', url, fileParam, data, showSuccess, isAbsoluteURL);
    }

    protected uploadPut(url: string, fileParam: any, showSuccess: boolean = true, isAbsoluteURL?: boolean)
    {
      return this.makeRequestUpload('PUT', url, fileParam, showSuccess, isAbsoluteURL);
    }

    
    private makeRequest(method:Method, url: string, data?: any, showSuccess: boolean = true, isAbsoluteURL?: boolean)
    {

      let fixedURL: string;
  
      if (isAbsoluteURL == true)
      {
        fixedURL = url;
      }
      else
      {
        fixedURL = this.baseUrl + url;
      }

      let headers: any = {};

      let authToken = localStorage.getItem("access_token");
      if (authToken != null) {
          headers.Authorization = `Bearer ${authToken.replace(/['"]+/g, '')}`;
      }


      return axios({
          url: fixedURL,
          method: method,
          data: data,
          params: method == "GET" ? data : null,
          headers: headers
        })
        .then(response => {

          if(method != "GET" && showSuccess)
          {
            toast.success("Success", {
              autoClose: 1000,
              });
          }

          return response.data;
        })
        .catch((error) => {

          toast.error(this.getErrorMessage(error));

        throw error;
      });
    }


    private getErrorMessage(error: any) : string
    {

      if(error?.response?.data?.message)
      {        
        return "Error.  " + error.response.data.message;
      }
      else
      {
        return "Error.";
      }
    }

    private makeRequestUpload(method:Method, url: string, fileParam: any, data?: any, showSuccess: boolean = true,  isAbsoluteURL?: boolean)
    {

      let formData: FormData = new FormData();

      // is a singe file => add it
      if (fileParam instanceof File)
      {
        formData.append(fileParam.name, fileParam );
      }
      else if (typeof fileParam == 'object' && fileParam?.length > 0 && fileParam[0] instanceof File )
      {
        for (let i = 0; i < fileParam.length; i++)
        {
          let file = fileParam[i];
          formData.append(file.name, file);
        }
      }
      else if (fileParam instanceof FileList)
      {
        for (let i = 0; i < fileParam.length; i++)
        {
          let file = fileParam[i];
          formData.append(file.name, file);
        }
      }



      if(data && typeof data == 'object')
      {
        if(Array.isArray(data))
        {
          data.forEach(dataItem => {
            for ( var key in dataItem ) 
            {
              formData.append(key, dataItem[key]);
            }
          });
        }
        else
        {
          for ( var key in data ) 
          {
            formData.append(key, data[key]);
          }
        }
      
      }

      let fixedURL: string;
  
      if (isAbsoluteURL == true)
      {
        fixedURL = url;
      }
      else
      {
        fixedURL = this.baseUrl + url;
      }

      let headers: any = {};

      let authToken = localStorage.getItem("access_token");
      if (authToken != null) {
          headers.Authorization = `Bearer ${authToken.replace(/['"]+/g, '')}`;
          headers["Content-Type"] = 'multipart/form-data';
      }

      return axios({
          url: fixedURL,
          method: method,
          data: formData,          
          headers: headers
        })
        .then(response => {
          
          if(method != "GET" && showSuccess) 
          {
            toast.success("Success",{
              autoClose: 2000,
              });
          }

          return response.data;
        })
        .catch(error => {

          toast.error(this.getErrorMessage(error));

        throw error;
      });
    }
  
 
}