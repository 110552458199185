import { PublicUserResponse } from "../models/response/public-user.response";
import {BaseService} from  "./base.service";
import { Product } from '../models/product';
import { KeyValue } from "../models/key-value";
import { ProductLite } from "../models/product-lite";


export class ProductService extends BaseService
{
    protected PRIMARY_RESOURCE = "products/";

    getListByProductCategoryId(productCategoryId: number) : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "productcategories/" + productCategoryId);
    }

    getLatestByUsername(username: string) : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "latest/users/" + username);
    }

    getProduct(id: number) : Promise<Product>
    {
        return this.get(this.PRIMARY_RESOURCE + id);
    }

    getStoreOtherProducts(productId: number) : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "getStoreOtherProducts/" + productId);
    }

    searchAllProducts(searchText: number) : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "searchAllProducts/" + searchText);
    }


    

    getProductsAsKeyValues() : Promise<KeyValue[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "getProductsAsKeyValues/");
    }




    
    

    
    getRandomProducts() : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "getRandomProducts");
    }

        
    getProductFeatureProducts() : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "getProductFeatureProducts");
    }



    getListUnSpecified(username: string) :  Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "users/" + username);
    }

    createProduct(product: Product, showSuccess: boolean = true) : Promise<Product>
    {
        return this.post(this.PRIMARY_RESOURCE, product, showSuccess)
    }

    updateProduct(product: Product, showSuccess: boolean = true) : Promise<Product>
    {
        return this.put(this.PRIMARY_RESOURCE + product.id, product, showSuccess)
    }

    upsertProduct(product: Product, showSuccess: boolean = true)
    {
        if(product.id)
        {
            return this.updateProduct(product, showSuccess);
        }
        else
        {
            return this.createProduct(product, showSuccess);
        }
    }

    getLatestForHomepage() : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "homepage");
    }

    getProductsForHomepage(page: number, pageSize?: number) : Promise<Product[]>
    {
        if(!pageSize)
        {
            pageSize = 10;
        }

        return this.get(this.PRIMARY_RESOURCE + "homepage/" + page + "/" + pageSize);
    }

    getProductsShowqaceCategoryLevelOne(showqaceCategoryId: number, page: number, pageSize?: number) : Promise<Product[]>
    {
        if(!pageSize)
        {
            pageSize = 10;
        }

        return this.get(this.PRIMARY_RESOURCE + "getProductsShowqaceCategoryLevelOne/" + showqaceCategoryId + "/" + page + "/" + pageSize);
    }

    getProductsShowqaceCategoryLevelTwo(showqaceCategoryId: number, page: number, pageSize?: number) : Promise<Product[]>
    {
        if(!pageSize)
        {
            pageSize = 10;
        }

        return this.get(this.PRIMARY_RESOURCE + "getProductsShowqaceCategoryLevelTwo/" + showqaceCategoryId + "/" + page + "/" + pageSize);
    }

    getCurrentUserProducts() : Promise<Product[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "currentUser");
    }


    
    getCurrentUserProductsLite() : Promise<ProductLite[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "getCurrentUserProductsLite");
    }

    getStoreProducts(username: string) : Promise<ProductLite[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "stores/" + username   + "/getStoreProducts");
    }

    
    // stores/{userId}/getStoreProducts

    productViewed(productId: number): Promise<any>
    {
        return this.post(this.PRIMARY_RESOURCE + productId + "/viewed", null, false);
    }

    // [HttpGet("search/{searchText}/users/{username}")]
    // public IActionResult SearchProducts(string searchText, string username)

    searchProducts(searchText: string, username: string)
    {
        searchText = encodeURIComponent(searchText);
        username = encodeURIComponent(username);

        return this.get(this.PRIMARY_RESOURCE + "search/" + searchText + "/users/" + username );
    }

    deleteProduct(id: number) : Promise<any>
    {
        return this.delete(this.PRIMARY_RESOURCE + id);
    }

    

    duplicateProduct(id: number) : Promise<Product>
    {
        return this.post(this.PRIMARY_RESOURCE + id + "/duplicate");
    }
    



}