import {BaseService} from  "./base.service";
import { Product } from '../models/product';
import { ProductCategory } from '../models/product-category';
import { Currency } from "../models/currency";
import { UserTweet } from '../models/user-tweet';


export class UserTweetService extends BaseService
{
    protected PRIMARY_RESOURCE = "userTweets/";

    getUserTweets(username: string) : Promise<UserTweet[]>
    {
        return this.get(this.PRIMARY_RESOURCE +"users/" + username );
    }

    
    createUserTweet(userTweet: UserTweet) : Promise<UserTweet>
    {
        return this.post(this.PRIMARY_RESOURCE, userTweet) ;
    }


    getUserTweet(userTweetId: number)
    {
        return this.get(this.PRIMARY_RESOURCE  + userTweetId );
    }

    createProductUserTweet(productId: number,  tweet: string) : Promise<UserTweet>
    {
        return this.post(this.PRIMARY_RESOURCE, {tweet: tweet, productId: productId}) ;
    }

    replyToUserTweet(userTweetId: number,  tweet: string) : Promise<UserTweet>
    {
        return this.post(this.PRIMARY_RESOURCE, {tweet: tweet, parentId: userTweetId}) ;
    }

    removeUserTweet(id: number) : Promise<any>
    {
        return this.delete(this.PRIMARY_RESOURCE + id);
    }

    getUserTweetLatest() :  Promise<UserTweet[]>
    {
        return this.get(this.PRIMARY_RESOURCE +"latest/");
    }

    // [HttpGet("products/users/{username}")]

    getProductUserTweetByUsername(username: string) :  Promise<UserTweet[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "products/users/" + username);
    }
}