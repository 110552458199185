import React from "react";
import { Link } from "react-router-dom";
import ProductCard from "../../components/product-card/ProductCard";
import { AppContextModel } from "../../models/context/App-Context-Model";
import { Product } from '../../models/product';
import { ProductService } from "../../services/product.service";
import { AppContext } from '../../lib/app-context';
import CreateUserTweet from '../../components/create-user-tweet/CreateUserTweet';
import UserTweetList from '../../components/user-tweet-list/UserTweetList';
import { UserTweetService } from '../../services/user-tweet.service';
import { UserTweet } from '../../models/user-tweet';
import { UserService } from '../../services/user.service';
import { PublicUserResponse } from '../../models/response/public-user.response';
import UserList from '../../components/user-list/UserList';
import LazyLoad from 'react-lazyload';
import { MergeService } from '../../services/merge.service';
import './ProductShowqaceCategoryLevelOne.scss';

import handleViewport from 'react-in-viewport';
import ViewportBlockSection from '../../components/viewport-block-section/ViewportBlockSection';
import { CommonService } from "../../services/common.service";
import { ShowqaceCategory } from "../../models/showqace-category";
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from "../../components/horizontal-scroll/arrows";
import { ScrollProduct } from "../../components/horizontal-scroll/scroll-product";
import { ScrollShowqaceCategory } from "../../components/horizontal-scroll/scroll-showqace-category";




const ViewportBlock = handleViewport(ViewportBlockSection, null, { disconnectOnLeave: true } /** options: {}, config: {} **/);


interface IProps {
}

interface IState {
  products: Product[];
  loadingProducts: boolean,
  page: number,
  prevY: number,
  allProductsLoaded: boolean,
  showqaceCategory: ShowqaceCategory
  // latestUsersWithProduct: PublicUserResponse[];
}


export default class ProductShowqaceCategoryLevelOne extends React.Component<IProps & any, IState>  {

  static contextType = AppContext;

  mergeService: MergeService = new MergeService();
  commonService: CommonService = new CommonService();
  productService: ProductService = new ProductService();
  userService: UserService = new UserService();

  observer: IntersectionObserver;
  loadingRef: any;

  showqaceCategoryId: number;

  state: IState = {
    products: [],
    loadingProducts: false,
    page: 1,
    prevY: 0,
    allProductsLoaded: false,
    showqaceCategory: null
    // latestUsersWithProduct: []

  };

  constructor(props) {
    super(props);

    this.showqaceCategoryId = +props.match.params.showqaceCategoryId;
  }


  async componentDidMount() {

    document.title = 'Showqace | The e-showcase';


    this.commonService.getShowqaceCategory(this.showqaceCategoryId).then(showqaceCategory => {
      this.setState({ showqaceCategory: showqaceCategory });
    })


    this.getAppendProducts(1);


    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );

    if (this.loadingRef) {
      this.observer.observe(this.loadingRef);
    }

  }


  getAppendProducts(page: number) {

    if (this.showqaceCategoryId) {
      this.setState({ loadingProducts: true });
      this.productService.getProductsShowqaceCategoryLevelOne(this.showqaceCategoryId, page, 5).then(nextPageproducts => {

        this.setState({ products: [...this.state.products, ...nextPageproducts] });
        this.setState({ loadingProducts: false });

        //all items loaded
        if (nextPageproducts?.length == 0) {
          if (this.loadingRef) {
            this.observer?.unobserve(this.loadingRef);
          }

          this.setState({ allProductsLoaded: true });
        }

      });
    }

  }

  componentDidUpdate() {
    if (this.loadingRef) {
      this.observer?.observe(this.loadingRef)
    }
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      // const lastProduct = this.state.products[this.state.products.length - 1];
      // const curPage = lastProduct.id;
      let nextPage = ++this.state.page;
      this.getAppendProducts(nextPage);
      this.setState({ page: nextPage });
    }
    this.setState({ prevY: y });
  }

  showqaceCategoryCardClicked(showqaceCategory: ShowqaceCategory) {
    this.props.history.push("/sub-category/" + showqaceCategory.id);
  }


  render() {

    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loadingProducts || this.state.allProductsLoaded ? "block" : "none" };

    return (
      <div>

        <div style={{ height: "45px" }}></div>
        <div style={{ position: "fixed", top: "53px", width: "100%", backgroundColor: "#FAFAFA", zIndex: 9 }}>
          <div className="container">
            <div className="d-flex flex-row align-items-center fs-2 mt-2">
              {
                // this.props.history.action !== 'POP' &&
                <Link className=" mb-1 me-2 primary-font-color" to={"/"}>
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              }

              <h3 className="">
                {this.state.showqaceCategory?.name}
              </h3>
            </div>
          </div>
        </div>

        <div className="container mt-2">



          <div>
            <ScrollMenu

              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
            // onWheel={this.onWheel}
            >
              {
                this.state.showqaceCategory?.children.map((showqaceCategory) =>
                (

                  <ScrollShowqaceCategory
                    showqaceCategory={showqaceCategory}
                    itemId={showqaceCategory.id.toString()} // NOTE: itemId is required for track items
                    key={showqaceCategory.id}
                    fireClickAction={() => this.showqaceCategoryCardClicked(showqaceCategory)}
                  />

                  // <ScrollItem
                  //   fireClickAction={() => this.initAndOnPropChange(this.state.userProductResponse, productCategory.id)}
                  //   // publicUserResponse={this.state.userProductResponse.publicUserResponse}
                  //   category={productCategory}
                  //   itemId={productCategory.id.toString()}
                  //   key={productCategory.id}
                  // />

                )
                )
              }


            </ScrollMenu>
          </div>


          <div className="row">

            <div className="col-md-10 col-lg-8 col-sm-12">
              <div className="row">


                <div className="mt-4">
                  {
                    this.state.products?.map((product) =>
                    (
                      <div key={product?.id} className="col-12">
                        {/* <ViewportBlock onEnterViewport={() =>  this.productViewed(product.id)} /> */}
                        <ProductCard product={product} />
                      </div>
                    )
                    )}
                </div>



                {
                  !this.state.allProductsLoaded &&
                  <div
                    ref={loadingRef => (this.loadingRef = loadingRef)}
                    className="mb-4"
                  >
                    {/* <span style={loadingTextCSS}>Loading...</span> */}

                    {
                      this.state.page == 1 &&
                      <span style={loadingTextCSS}>Loading...</span>
                    }

                    {
                      this.state.page != 1 &&
                      <div className="spinner-border-wrapper mt-2" >
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    }

                  </div>
                }
                {
                  this.state.allProductsLoaded &&
                  <div className="text-center p-2 bold mb-4" style={loadingTextCSS}>That's all folks!</div>
                }
              </div>
            </div>






          </div>
        </div>
      </div>
    );
  }
}

