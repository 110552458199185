import { Switch, Route } from "react-router-dom";
import GuardedRoute from "../route-guard/RouteGuard"
import React, { Suspense } from "react";
import { AppContext } from '../../lib/app-context';
import MaintenanceReadonly from "../../pages/maintenance-readonly/MaintenanceReadonly";
import ProductShowqaceCategoryLevelOne from "../../pages/product-showqace-category-level-one/ProductShowqaceCategoryLevelOne";
import ProductShowqaceCategoryLevelTwo from "../../pages/product-showqace-category-level-two/ProductShowqaceCategoryLevelTwo";


const AboutUs = React.lazy(() => import('../../pages/about-us/AboutUs'));
const ContactUs = React.lazy(() => import('../../pages/contact-us/ContactUs'));
const Login = React.lazy(() => import('../../pages/login/Login'));
const Privacy = React.lazy(() => import('../../pages/privacy/Privacy'));
const UserDataDeletion = React.lazy(() => import('../../pages/user-data-deletion/UserDataDeletion'));
const Notification = React.lazy(() => import('../../pages/notification/Notification'));
const UserSettings = React.lazy(() => import('../../pages/user-settings/UserSettings'));
const StoreDiscovery = React.lazy(() => import('../../pages/store-discovery/StoreDiscovery'));
// const UserProducts = React.lazy(() => import('../../pages/user-products/UserProducts'));
const Verify = React.lazy(() => import('../../pages/verify/Verify'));
const UserTweetDetail = React.lazy(() => import('../../pages/user-tweet-detail/User-Tweet-Detail'));
const UserProfile = React.lazy(() => import('../../pages/user-profile/UserProfile'));
const ProductList = React.lazy(() => import('../../pages/product-list/Product-List'));
const ProductSave = React.lazy(() => import('../../pages/product-save/Product-Save'));
const ProductCategorySave = React.lazy(() => import('../../pages/product-category-save/Product-Category-Save'));
const ProductDetail = React.lazy(() => import('../../pages/product-detail/Product-Detail'));

const Promotion = React.lazy(() => import('../../pages/promotion/Promotion'));

const HomeUser = React.lazy(() => import('../../pages/home-user/HomeUser'));

const Home = React.lazy(() => import('../../pages/home/Home'));




export default class AppRouter extends React.Component {

  static contextType = AppContext;

  render() {
    return (
      <Suspense fallback={
        <div className="spinner-border-wrapper">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}>
        <Switch>

          <Route path="/promotion" component={Promotion} />

          <Route path="/maintenance/readonly" component={MaintenanceReadonly} />
          <Route path="/about-us" component={AboutUs} />
          <Route path='/contact-us' component={ContactUs} />

          <Route path='/category/:showqaceCategoryId' component={ProductShowqaceCategoryLevelOne} />
          <Route path='/sub-category/:showqaceCategoryId' component={ProductShowqaceCategoryLevelTwo} />



          <Route path='/login' component={Login} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/user-data-deletion' component={UserDataDeletion} />
          <GuardedRoute path="/notifications" component={Notification} auth={this.context.isAuthenticated} />

          <GuardedRoute path="/user/user-settings" component={UserSettings} auth={this.context.isAuthenticated} />
          <GuardedRoute path="/user/products" component={ProductList} auth={this.context.isAuthenticated} />

          <Route path='/store-discovery/:storeCategoryIdSelected?' component={StoreDiscovery} />


          <Route path="/verify/:verificationCode" component={Verify} />
          <Route path="/userPost/:userTweetId" component={UserTweetDetail} />

          <GuardedRoute path="/user/:username/product-category-save" component={ProductCategorySave} auth={this.context.isAuthenticated} />

          <GuardedRoute path="/product/save/:productId" component={ProductSave} auth={this.context.isAuthenticated} />


          <Route path="/:username/:productName/:productId" component={ProductDetail} />
          <Route path="/:username" component={UserProfile} />


          <Route path="/">
            <Home />
          </Route>

        </Switch>
      </Suspense>
    )
  }
}

