import React from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import "./RichEditorDisplay.scss";




interface IProps {
  editorState: any;
}

interface IState {
  editorState: any;
}

export class RichEditorDisplay extends React.Component<IProps, IState> {

  refs: any;

  editorState: any;

  constructor(props: IProps) {
    super(props);

    this.editorState = props.editorState;
  }

  async componentDidMount() {
    this.setState({ editorState: this.props.editorState });
  }


  render() {

    return (
      <div className="rich-editor-display-wrapper">
          <Editor
              readOnly={true}
              editorState={this.editorState}
            />
      </div>
    );
  }
}

