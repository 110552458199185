export enum ExternalLoginProvider
{
    Facebook = 1
}

export enum NotificationTypeEnum
{
    ProductLike = 1,
    TweetLike = 2,
    NewProduct = 3,
    NewProductComment = 4,
    CommentMention = 5,
    NewFollower = 6
}

export enum SortEnum
{
    DateDesc = 1,
    DateAsc = 2,
    NameDesc = 3,
    NameAsc = 4,
    PriceDesc = 5,
    PriceAsc = 6,
}

export enum ProductStatusEnum
{
    Enabled = 1,
    Disabled = 2,
    OutOfStock = 3
}

export enum ProductFeatureStatusEnum
{
    Created = 1,
    PaymentWaitingConfirmation = 2,
    PaymentConfirmed = 3,
    Cancelled = 4,
    Deleted = 5,
}

export enum UserLevelEnum
{
    Admin = 1,
    PowerUser = 2,
    RegularUser = 3,
    BusinessUser = 4,
}


export enum CurrencyEnum
{
    USD = 1,
    LBP = 2
}
