import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({
  children,
  disabled,
  onClick,
  isLeft,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  isLeft: boolean;
}) {
  return (
    !disabled &&
    <button
      disabled={disabled}
      className="d-flex align-items-center d-none d-lg-block"
      onClick={onClick}
      style={isLeft ? {
        // cursor: "pointer",
        // display: "flex",
        position: "absolute",
        left: 0,
        border: "none",
        backgroundColor: "transparent",
        fontSize: "2em",
        padding: "10px",
        // flexDirection: "column",
        // justifyContent: "center",
        // right: "1%",
        opacity: disabled ? "0" : "0.5",
        zIndex: 99,
        height: "100%",
        // userSelect: "none"
      }
        :
        {
          position: "absolute",
          right: 0,
          border: "none",
          backgroundColor: "transparent",
          fontSize: "2em",
          padding: "10px",
          // flexDirection: "column",
          // justifyContent: "center",
          // right: "1%",
          opacity: disabled ? "0" : "0.5",
          zIndex: 99,
          height: "100%",
        }


      }
    >
      {children}
    </button>

  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow isLeft={true} disabled={disabled} onClick={() => scrollPrev()}>
      <i className="bi bi-arrow-left-circle-fill"></i>
    </Arrow>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow isLeft={false} disabled={disabled} onClick={() => scrollNext()} >
      <i className="bi bi-arrow-right-circle-fill"></i>
    </Arrow>
  );
}
