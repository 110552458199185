import React from "react";
// import logo from "../../logo.svg";
import AppRouter from "../app-router/AppRouter";
import NavBar from "../../components/nav/NavBar";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContext } from '../../lib/app-context';
import { AppContextModel } from '../../models/context/App-Context-Model';
import { AuthService } from "../../services/auth.service";
import { ToastContainer } from "react-toastify";
import { loadProgressBar } from 'x-axios-progress-bar';
import { Footer } from "../footer/Footer"


// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

// end swipper CSS

import 'react-sortable-tree/style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'x-axios-progress-bar/dist/nprogress.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'



import "./App.scss";



interface IProps {
}

// export interface IAppState {
//   isAuthenticated?: boolean;
//   setIsAuthenticated: any
// }


export default class App extends React.Component<IProps, AppContextModel>  {

  authService: AuthService = new AuthService();

  state =
    {
      isAuthenticated: undefined,
      setIsAuthenticated: (value) => { },
      currentUser: null,
      setCurrentUser: (value) => { }
    };

  constructor(props) {
    super(props);

    loadProgressBar({ easing: 'ease', speed: 500, showSpinner: false });

    this.state.setIsAuthenticated = (value) => {
      this.setState({ isAuthenticated: value })
    };

    this.state.setCurrentUser = (value) => {
      this.setState({ currentUser: value })
    };

  }


  componentDidMount() {

    this.getCurrentUser();

  }

  async getCurrentUser() {
    let access_token = localStorage.getItem("access_token");

    if (!access_token) {
      this.state.setIsAuthenticated(false);
    }
    else {
      this.authService.getCurrentUser().then(user => {
        this.state.setCurrentUser(user);
        this.state.setIsAuthenticated(true);
      })
        .catch(() => {
          this.state.setIsAuthenticated(false);
          this.state.setCurrentUser(null);
          localStorage.removeItem("access_token");
        });

      return true
    }
  }


  render() {

    return (
      <>

        <ToastContainer />

        <div className="serge-wrapper">


          <AppContext.Provider value={this.state}>
            {/* {
          this.state.isAuthenticated === true &&
          <div>
            Authenticated
            </div>
        }
        {
          this.state.isAuthenticated === false &&
          <div>
            Need to login
            </div>
        }
        {
          this.state.isAuthenticated === null &&
          <div>
            is NULL
            </div>
        }
        {
          this.state.isAuthenticated === undefined &&
          <div>
            is undefined
        </div>
        } */}

            {(this.state.isAuthenticated === true || this.state.isAuthenticated === false) &&

              <Router>
                <NavBar></NavBar>
                <div className="page-body">
                  <AppRouter />
                </div>

                <Footer />

              </Router>
            }

            {/* <header className="page-header">This is the header</header> */}
            {/* <article className="page-body">
              <p>Main page content here, add more if you want to see the footer push down.</p>
            </article> */}
            {/* <footer className="page-footer">Sticky footer</footer> */}



          </AppContext.Provider>
        </div>

      </>
    );
  }
}


