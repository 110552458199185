import React from "react";

import { ProductImage } from '../../models/product-image';
import { AppContext } from '../../lib/app-context';
import "./ProductCardImage.scss";
import ColorThief from "colorthief";
import LazyLoad from 'react-lazyload';


interface IProps {
  productImage: ProductImage;
  productImages: ProductImage[];
}

interface IState {
  productImage: ProductImage;
  productImages: ProductImage[];
  minHeight: number,
  dynamicBackgroundColor: string
}


export default class ProductCardImage extends React.Component<IProps, IState> {

  static contextType = AppContext;

  imgRef: any;

  state: IState =
    {
      productImage: null,
      productImages: [],
      minHeight: 100,
      dynamicBackgroundColor: "transparent"
    }

  constructor(props: IProps) {
    super(props);
  }

  async componentDidMount() {

    let productImages = [];

    if (this.props.productImages?.length > 0 && this.props.productImage?.id) {
      productImages = this.props.productImages.filter(x => x.id != this.props.productImage.id);

      if (productImages.length > 3) {
        productImages = productImages.splice(0, 3);
      }
    }

    this.setState({ productImage: this.props.productImage });
    this.setState({ productImages: productImages });

    this.imgRef = React.createRef();
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

    getImageHeightViewport(test: any) : number
  {
    // let imageHeight = this.state.productImage.imageHeight;
    // let imageWidth = this.state.productImage.imageWidth;
    // let proportionHeight = (imageHeight * this.divRef.current.offsetWidth)/imageWidth;
    // let viewPortHeight =  proportionHeight > 600 ? 600 :proportionHeight;
    return 100;
  }


  render() {

    return (
      <div>
        {
          this.state.productImage?.imageFullURL &&
          <div className="card-product-image-wrapper" style={{
            objectFit: "contain", width: "100%",
            textAlign: "center", backgroundColor: this.state.dynamicBackgroundColor,
            minHeight: this.getImageHeightViewport(this)
            // minHeight: this.state.productImage.imageHeight > 600 ? "600" : this.state.productImage.imageHeight
          }} >
            <LazyLoad offset={100} once>
              <img
                crossOrigin={"anonymous"}
                ref={this.imgRef}
                src={this.state.productImage.imageFullURL}
                className="img-fluid product-card-image"
                // style={{ maxHeight: 600 }}
                onLoad={() => {

                  const colorThief = new ColorThief();
                  const img = this.imgRef.current;
                  const resultRGB = colorThief.getColor(img, 25);

                  if (resultRGB?.length == 3) {
                    let hex = this.rgbToHex(resultRGB[0], resultRGB[1], resultRGB[2]);
                    this.setState({ dynamicBackgroundColor: hex });
                  }

                }}
              />
            </LazyLoad>
          </div>
        }
      </div>
    )
  }
}

