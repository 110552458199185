import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
// import 'jquery/dist/jquery.min.js';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min.js';

import '@fortawesome/fontawesome-free/css/all.css';

import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';


import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en-AU';
TimeAgo.addDefaultLocale(en);

let sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED == "true" ? true : false;

if(sentryEnabled)
{
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: +process.env.REACT_APP_SENTRY_TRACESSAMPLERATE,

    debug: process.env.REACT_APP_SENTRY_DEBUG === 'true'
  });
}

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
