
import React from 'react';



interface IProps {
}

interface IState {
}


export default class MaintenanceReadonly extends React.Component<IProps, IState>  {


  maintenanceImageFullUrl : string =  process.env.REACT_APP_CLOUD_BASE_URL_PUBLIC_IMAGES + "construction-warning-sign-icon-concept.jpg";

  state: IState = {
  };


  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    document.title = 'Showqace | Maintenance Notice';
  }


  render() {

    return (
      <div className="page-wrapper container mt-2 pt-4">

        <div className="row mt-3">

          <div className="col-md-6">
            <img className='img-fluid rounded' src={this.maintenanceImageFullUrl} alt="construction-warning-sign" />
          </div>


          <div className="col-md-6">
            <h3>
              Website Under Maintenance
            </h3>
            <br />
            <p>
              Website is currently in read-only mode. <br/><br/>
              The good news is that you will be able to browser, search, and contact sellers.<br/><br/>
              The bad news is that new users cannot be created and sellers cannot add new items or update old items. <br /><br />
              We thank you for your patience as we work on fixing the issue.<br /><br />

              Disabled Features:
            </p>
            <ul>
              <li>Login/Register</li>
              <li>Product Create/Update/Delete</li>
            </ul>
          </div>


        </div>

      </div>
    );
  }
}
