import {BaseService} from  "./base.service";
import { Product } from '../models/product';
import { ProductCategory } from '../models/product-category';
import { Currency } from "../models/currency";
import { NotificationDomain } from '../models/notification-domain';


export class NotificationService extends BaseService
{
    protected PRIMARY_RESOURCE = "notifications/";

    getCurrentUserNotifications() : Promise<NotificationDomain[]>
    {
        return this.get(this.PRIMARY_RESOURCE);
    }

    getCurrentUserNotificationsMarkViewed() : Promise<NotificationDomain[]>
    {
        return this.get(this.PRIMARY_RESOURCE + "viewed");
    }

    deleteNotification(notificationDomain: NotificationDomain) : Promise<any>
    {
        return this.delete(this.PRIMARY_RESOURCE, notificationDomain);
    }
}