
import React, { Fragment } from "react";
import { AppContext } from '../../lib/app-context';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Redirect,
    Link,
    withRouter
} from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { UserService } from '../../services/user.service';
import { PublicUserResponse } from '../../models/response/public-user.response';
import './NavBar.scss';
import { NotificationService } from '../../services/notification.service';
import { getProductDetailFullUrl, getProductUrl, getUserProfileUrl, getUserStoreUrl, toSeoUrl } from '../../lib/common-helper';
import { UserLevelEnum } from "../../lib/enums";
import { ProductService } from "../../services/product.service";
import { Product } from "../../models/product";


class NavBar extends React.Component<any, any> {

    static contextType = AppContext;

    productService: ProductService = new ProductService();
    userService: UserService = new UserService();
    notificationService: NotificationService = new NotificationService();

    state: any = {
        defaultImage: '',
        searchIsLoading: false,
        searchOptions: [],
        searchSelections: [],
        redirect: null,
        notificationsNumber: 0
    }

    async componentDidMount() {
        this.setState({ defaultImage: process.env.REACT_APP_DEFAULT_USER_IMAGE });

        if (this.context?.currentUser?.userId) {
            var notifications = await this.notificationService.getCurrentUserNotifications();

            var newNotification = notifications.filter(x => x.viewed == false);

            if (newNotification?.length > 0) {
                this.setState({ notificationsNumber: newNotification.length });
            }
        }
    }

    handleSearch(searchText: any) {
        this.setState({ searchIsLoading: true });

        this.productService.searchAllProducts(searchText).then(result => {
            this.setState({ searchOptions: result });
            this.setState({ searchIsLoading: false });
        });


        // this.userService.searchUsers(searchText).then(result => {
        //     this.setState({ searchOptions: result });
        //     this.setState({ searchIsLoading: false });
        // });


    }

    searchOnSelect(products: Product[]) {
        // this.props.history.push(getUserProfileUrl(publicUserResponse[0].username));
        this.props.history.push(getProductUrl(products[0]));
    }

    logout() {
        this.context.setIsAuthenticated(false);
        this.context.setCurrentUser(null);
        localStorage.removeItem("access_token");
    }

    setlinkActive(linkName: string) {
        let currentUrl = this.props.history?.location?.pathname;

        if (currentUrl) {
            if (linkName == 'aboutUs') {
                if (currentUrl.endsWith("about-us")) {
                    return "active";
                }
            }
            else if (linkName == 'storeDiscovery') {
                if (currentUrl.endsWith("store-discovery")) {
                    return "active";
                }
            }
            else if (linkName == 'notifications') {
                if (currentUrl.endsWith("notifications")) {
                    return "active";
                }
            }
            else if (linkName == 'createProduct') {
                if (currentUrl.endsWith("save/0")) {
                    return "active";
                }
            }
            else if (linkName == 'login') {
                if (currentUrl.endsWith("login")) {
                    return "active";
                }
            }
            else if (linkName == 'products') {
                if (currentUrl.endsWith("user/products")) {
                    return "active";
                }
            }
            else if (linkName == 'productCategories') {
                if (currentUrl.endsWith("/product-category-save")) {
                    return "active";
                }
            }
            else if (linkName == 'publicUserProducts') {
                if (currentUrl.endsWith("/" + toSeoUrl(this.context?.currentUser?.username))) {
                    return "active";
                }
            }
        }

        return "";

    }


    render() {

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

        return (
            <div>
                <nav className="p-0 navbar main-nav fixed-top navbar-expand-lg navbar-light" style={{ borderBottom: "solid 1px #DBDBDB", minHeight: "53px", }}>
                    <div className="container d-flex align-items-center" >
                        <Link className="navbar-brand fs-4" to="/" >
                            <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                Showqace
                            </span>
                        </Link>

                        <form className="d-inline d-lg-none">
                            <AsyncTypeahead
                                style={{ width: "165px" }}
                                filterBy={() => true}
                                id="nav-bar-user-search"
                                isLoading={this.state.searchIsLoading}
                                labelKey="name"
                                minLength={1}
                                onChange={(searchText) => this.searchOnSelect(searchText)}
                                selected={this.state.searchSelections}
                                onSearch={(searchText) => this.handleSearch(searchText)}
                                options={this.state.searchOptions}
                                placeholder="Search for products"
                                renderMenuItemChildren={(option, props) => (
                                    <Fragment>
                                        <div className="d-flex align-items-center">

                                            <img
                                                alt={option.name}
                                                src={option?.productImage?.thumbnailImageFullURL}
                                                style={{
                                                    height: '45px',
                                                    marginRight: '10px',
                                                    width: '45px',
                                                }}
                                            />
                                            <div style={{
                                                wordBreak: "break-all",
                                                whiteSpace: "nowrap", overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                                <div style={{
                                                    color: "black", wordBreak: "break-all",
                                                    whiteSpace: "nowrap", overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}>{option.name}</div>
                                                <div className="grey fs-8">{option?.publicUserResponse?.name}</div>
                                            </div>


                                        </div>
                                    </Fragment>
                                )}
                            />
                        </form>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">

                            <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">

                                {
                                    !this.context?.currentUser?.userId &&
                                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        <Link className={"nav-link ms-2 fs-5 " + this.setlinkActive("storeDiscovery")} to="/store-discovery">
                                            <i className="bi bi-compass"></i>
                                            <span className="ms-1 pe-1 d-lg-none">
                                                Discover Stores
                                            </span>
                                        </Link>
                                    </li>
                                }

                                {
                                    !this.context?.currentUser?.userId &&
                                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        <Link className={"nav-link ms-2 fs-5 " + this.setlinkActive("aboutUs")} to="/about-us">
                                            <i className="bi bi-info-circle"></i>
                                            <span className="ms-1 pe-1 d-lg-none">
                                                About us
                                            </span>
                                        </Link>
                                    </li>
                                }


                                {
                                    !this.context?.currentUser?.userId &&

                                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        <Link className={"nav-link ms-2 fs-5 " + this.setlinkActive("login")} to="/login">
                                            {/* Login/Register */}
                                            <i className="bi bi-person"></i>
                                            <span className="ms-1 pe-1 d-lg-none">
                                                Login/Register
                                            </span>
                                        </Link>
                                    </li>
                                }

                                <li className="ms-2 me-2 d-none d-lg-block">
                                    <form className="d-flex" >
                                        <AsyncTypeahead
                                            style={{ minWidth: 400 }}
                                            filterBy={() => true}
                                            id="nav-bar-user-search"
                                            isLoading={this.state.searchIsLoading}
                                            labelKey="name"
                                            minLength={1}
                                            onChange={(searchText) => this.searchOnSelect(searchText)}
                                            selected={this.state.searchSelections}
                                            onSearch={(searchText) => this.handleSearch(searchText)}
                                            options={this.state.searchOptions}
                                            placeholder="Search for products"
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <div className="d-flex align-items-center">

                                                        <img
                                                            alt={option.name}
                                                            src={option?.productImage?.thumbnailImageFullURL}
                                                            style={{
                                                                height: '45px',
                                                                marginRight: '10px',
                                                                width: '45px',
                                                            }}
                                                        />
                                                        <div style={{
                                                            wordBreak: "break-all",
                                                            whiteSpace: "nowrap", overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}>
                                                            <div style={{
                                                                color: "black", wordBreak: "break-all",
                                                                whiteSpace: "nowrap", overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                            }}>{option.name}</div>
                                                            <div className="grey fs-8">{option?.publicUserResponse?.name}</div>
                                                        </div>


                                                    </div>
                                                </Fragment>
                                            )}
                                        />
                                    </form>
                                </li>

                                {
                                    this.context?.currentUser?.userId &&
                                    <>
                                        {
                                            this.context.currentUser.userLevelId == UserLevelEnum.RegularUser &&
                                            <>
                                                <li>
                                                    <Link title="Discover Stores" className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("storeDiscovery")} to="/store-discovery">
                                                        <i className="bi bi-compass"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            Discover Stores
                                                        </span>
                                                    </Link>
                                                </li>
                                            </>
                                        }
                                        {
                                            this.context.currentUser.userLevelId == UserLevelEnum.BusinessUser &&
                                            <>
                                                <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link title="New Product" className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("createProduct")} to="/product/save/0">
                                                        <i className="bi bi-plus-lg btn btn-primary"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            New Product
                                                        </span>
                                                    </Link>
                                                </li>



                                                <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link title="My Products" className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("products")} to="/user/products">
                                                        <i className="bi bi-list-ul"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            My Products
                                                        </span>
                                                    </Link>
                                                </li>

                                                <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link title="My Product Categories" className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("productCategories")}
                                                        to={"/user/" + this.context?.currentUser.username + "/product-category-save"}>
                                                        <i className="bi bi-diagram-3"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            My Product Categories
                                                        </span>
                                                    </Link>
                                                </li>

                                                <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link title="View Store" className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("publicUserProducts")}
                                                        to={getUserStoreUrl(this.context?.currentUser.username)}>
                                                        <i className="bi bi-shop-window"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            View Store
                                                        </span>
                                                    </Link>
                                                </li>

                                                {/* <li className="d-lg-none" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("promotion")}
                                                        to={"/promotion"}>
                                                        <i className="bi bi-currency-dollar"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            Promotion
                                                        </span>
                                                    </Link>
                                                </li> */}



                                                <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link title="Discover Stores" className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("storeDiscovery")} to="/store-discovery">
                                                        <i className="bi bi-compass"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            Discover Stores
                                                        </span>
                                                    </Link>
                                                </li>

                                            </>
                                        }


                                        {
                                            (
                                                this.context.currentUser.userLevelId == UserLevelEnum.PowerUser ||
                                                this.context.currentUser.userLevelId == UserLevelEnum.Admin
                                            ) &&
                                            <>
                                                <li className="d-lg-none" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <Link className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("promotion")}
                                                        to={"/promotion"}>
                                                        <i className="bi bi-currency-dollar"></i>
                                                        <span className="ms-1 pe-1 d-lg-none">
                                                            Promotion
                                                        </span>
                                                    </Link>
                                                </li>
                                            </>
                                        }


                                        <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <Link title="Notifications" className={"d-flex nav-link ms-2 me-2 fs-5 " + this.setlinkActive("notifications")}
                                                to={"/notifications"} onClick={() => { this.setState({ notificationsNumber: 0 }) }}>
                                                <i className="bi bi-bell"></i>
                                                {
                                                    this.state.notificationsNumber > 0 &&
                                                    <span>
                                                        {this.state.notificationsNumber}
                                                    </span>
                                                }
                                                <span className="ms-1 pe-1 d-lg-none">
                                                    Notifications
                                                </span>
                                            </Link>
                                        </li>





                                        <li className="d-lg-none" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <Link className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("profile")}
                                                to={getUserProfileUrl(this.context.currentUser.username)}>
                                                <i className="bi bi-person"></i>
                                                <span className="ms-1 pe-1 d-lg-none">
                                                    Profile
                                                </span>
                                            </Link>
                                        </li>

                                        <li className="d-lg-none" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <Link className={"nav-link ms-2 me-2 fs-5 " + this.setlinkActive("settings")}
                                                to={"/user/user-settings"}>
                                                <i className="bi bi-gear"></i>
                                                <span className="ms-1 pe-1 d-lg-none">
                                                    Settings
                                                </span>
                                            </Link>
                                        </li>

                                        <li className="d-lg-none" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <span className={"nav-link ms-2 me-2 fs-5 pointer primary-font-color"}
                                                onClick={() => this.logout()}>
                                                {/* <i className="bi bi-shop-window"></i> */}
                                                <i className="bi bi-box-arrow-left"></i>
                                                <span className="ms-1 pe-1 d-lg-none">
                                                    Logout
                                                </span>
                                            </span>
                                        </li>


                                        {/* <li className="mt-4 mb-2 ms-4 me-2 d-lg-none" style={{ width: "100%" }}>
                                            <form className="d-flex" >
                                                <AsyncTypeahead
                                                    style={{ width: "100%" }}
                                                    filterBy={() => true}
                                                    id="nav-bar-user-search"
                                                    isLoading={this.state.searchIsLoading}
                                                    labelKey="name"
                                                    minLength={1}
                                                    onChange={(searchText) => this.searchOnSelect(searchText)}
                                                    selected={this.state.searchSelections}
                                                    onSearch={(searchText) => this.handleSearch(searchText)}
                                                    options={this.state.searchOptions}
                                                    placeholder="Search for products"
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <div className="d-flex align-items-center">

                                                                <img
                                                                    alt={option.name}
                                                                    src={option?.productImage?.thumbnailImageFullURL}
                                                                    style={{
                                                                        height: '45px',
                                                                        marginRight: '10px',
                                                                        width: '45px',
                                                                    }}
                                                                />
                                                                <div>
                                                                    <span style={{ color: "black" }}>{option.name}</span>
                                                                    <div className="grey fs-8">{option?.publicUserResponse?.name}</div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </Fragment>
                                                    )}
                                                />
                                            </form>
                                        </li> */}

                                    </>
                                }



                                {/* <li className="nav-item dropdown me-2">
                                <Link className="nav-link" to="/user/products">
                                    <i className="bi bi-shop-window me-1"></i>
                                    Products
                                </Link>
                            </li>

                            <li className="nav-item dropdown me-3">
                                <Link className="nav-link" to="/user/admin/product-category-save">
                                    <i className="bi bi-diagram-3 me-1"></i>
                                    Product Categories
                                </Link>
                            </li> */}

                                {
                                    this.context?.currentUser?.userId &&

                                    <li className="ms-2 nav-item dropdown align-self-center me-2 d-none d-lg-block">
                                        {

                                            <a className="nav-link dropdown-toggle p-0" href="#" id="navbarDropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                {
                                                    this.context?.currentUser?.avatarFullURL &&
                                                    <img className="img-circle rounded-circle"
                                                        height={40}
                                                        width={40}
                                                        src={this.context.currentUser.avatarFullURL}
                                                        alt="Account" />
                                                }
                                                {

                                                    this.context?.currentUser?.userId &&
                                                    !this.context?.currentUser?.avatarFullURL &&
                                                    this.state.defaultImage &&

                                                    <img className="img-circle rounded-circle"
                                                        height={34}
                                                        src={this.state.defaultImage}
                                                        alt="Avatar" />
                                                }

                                            </a>
                                        }


                                        <ul className="dropdown-menu user-dropdown-menu" style={{ left: "-135px" }} aria-labelledby="navbarDropdown">
                                            {
                                                this.context?.currentUser?.userId &&
                                                <>

                                                    {
                                                        this.context.currentUser.userLevelId == UserLevelEnum.BusinessUser &&
                                                        <>
                                                            <li>
                                                                <Link className="dropdown-item" to="/user/products">
                                                                    <i className="bi bi-list-ul me-1"></i>
                                                                    My Products
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className="dropdown-item" to="/user/admin/product-category-save">
                                                                    <i className="bi bi-diagram-3 me-1"></i>
                                                                    My Product Categories
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className="dropdown-item" to={getUserStoreUrl(this.context?.currentUser.username)}>
                                                                    <i className="bi bi-shop-window me-1"></i>
                                                                    Profile / View Store
                                                                </Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link className="dropdown-item" to={"/promotion"}>
                                                                    <i className="bi bi-currency-dollar me-1"></i>
                                                                    Promotion
                                                                </Link>
                                                            </li> */}
                                                        </>
                                                    }

                                                    {
                                                        (
                                                            this.context.currentUser.userLevelId == UserLevelEnum.Admin ||
                                                            this.context.currentUser.userLevelId == UserLevelEnum.PowerUser
                                                        ) &&
                                                        <>
                                                            <li>
                                                                <Link className="dropdown-item" to={"/promotion"}>
                                                                    <i className="bi bi-currency-dollar me-1"></i>
                                                                    Promotion
                                                                </Link>
                                                            </li>
                                                        </>
                                                    }

                                                    <li>
                                                        <Link className="dropdown-item"
                                                            onClick={() => { this.setState({ notificationsNumber: 0 }) }}
                                                            to={"/notifications"} >
                                                            {
                                                                this.state.notificationsNumber > 0 &&
                                                                <span>
                                                                    {this.state.notificationsNumber}
                                                                </span>
                                                            }
                                                            <i className="bi bi-bell me-1"></i>
                                                            Notifications
                                                        </Link>
                                                    </li>

                                                    {
                                                        this.context.currentUser.userLevelId != UserLevelEnum.BusinessUser &&
                                                        <li>
                                                            <Link className="dropdown-item" to={getUserProfileUrl(this.context.currentUser.username)}>
                                                                <i className="bi bi-person me-1"></i>
                                                                Profile
                                                            </Link>
                                                        </li>
                                                    }


                                                    <li>
                                                        <Link className="dropdown-item" to={"/user/user-settings"}>
                                                            <i className="bi bi-gear me-1"></i>
                                                            Settings
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <span className="dropdown-item pointer" onClick={() => this.logout()} >
                                                            <i className="bi bi-box-arrow-left me-1"></i>
                                                            Logout
                                                        </span>
                                                    </li>
                                                </>
                                            }
                                            {
                                                !this.context?.currentUser?.userId &&
                                                <>

                                                    <li>
                                                        <Link className="dropdown-item" to="/login">Login</Link>
                                                    </li>
                                                </>
                                            }

                                        </ul>
                                    </li>

                                }


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }

}




export default withRouter(NavBar);