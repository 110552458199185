import {BaseService} from  "./base.service";
import { SettingPageResponse } from '../models/response/setting-page-response';


export class MergeService extends BaseService
{
    protected PRIMARY_RESOURCE = "merge/";

    home() : Promise<any>
    {
        return this.get(this.PRIMARY_RESOURCE + "home");
    }


    
    settingPage() : Promise<SettingPageResponse>
    {
        return this.get(this.PRIMARY_RESOURCE + "settingPage");
    }


    
}