import { LoginRequest } from "../models/request/login.request";
import {BaseService} from  "./base.service";
import { UserSessionResponse } from '../models/response/user-session.response';
import { ExternalLoginRequest } from '../models/request/external-login.request';
import { UserRegisterRequest } from '../models/request/user-register.request';


export class AuthService extends BaseService
{
    protected PRIMARY_RESOURCE = "auth/";

    login(emailOrUsername: string, password: string) : Promise<{access_token: string, user: UserSessionResponse}>
    {
        let loginRequest : LoginRequest = new LoginRequest();
        loginRequest.emailOrUsername = emailOrUsername;
        loginRequest.password = password;
        
        return this.post(this.PRIMARY_RESOURCE + "login", loginRequest);
    }

    externalLogin(externalLoginRequest: ExternalLoginRequest) : Promise<{access_token: string, user: UserSessionResponse}>
    {
        return this.post(this.PRIMARY_RESOURCE + "externalLogin", externalLoginRequest);
    }

    registerUser(userRegisterRequest: UserRegisterRequest) : Promise<{access_token: string, user: UserSessionResponse}>
    {
        return this.post(this.PRIMARY_RESOURCE + "registerUser", userRegisterRequest);
    }

    getCurrentUser() : Promise<UserSessionResponse>
    {
        return this.get(this.PRIMARY_RESOURCE + "currentUser");
    }

    weatherTest() : Promise<any>
    {
        return this.get("WeatherForecast", {test: '1234'});
    }

}