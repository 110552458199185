
import React, { Fragment } from "react";
import { AppContext } from '../../lib/app-context';

import './Footer.scss';
import { Link } from 'react-router-dom';

export class Footer extends React.Component<any, any> {

    static contextType = AppContext;

    state: any = {
    }

    componentDidMount() {
        this.setState({ defaultImage: process.env.REACT_APP_DEFAULT_USER_IMAGE });
    }


    render() {

        return (
            <footer className="page-footer-wrapper">
                <div className="container">

                    <div className="row fs-7">
                        <div className="col-md-4">

                            <nav className="nav flex-column">

                                <Link to={"/about-us"} className="nav-link">
                                    About us
                                </Link>




                                <Link to={"/contact-us"} className="nav-link">
                                    Contact us
                                </Link>

                            </nav>

                        </div>
                        <div className="col-md-4">

                            <nav className="nav flex-column">

                                <Link to={"/login"} className="nav-link">
                                    Login/Regsiter
                                </Link>

                            </nav>

                        </div>
                        <div className="col-md-4">

                            <nav className="nav flex-column">

                                <Link to={"/privacy"} className="nav-link">
                                    Privacy Policy
                                </Link>

                                <Link to={"/user-data-deletion"} className="nav-link">
                                    User Data Deletion
                                </Link>

                            </nav>

                        </div>
                        <div className="col-md-4">

                        </div>
                    </div>

                    <div className="row mt-4 fs-8">
                        <div className="col">

                            <div className="mt-2 text-center">
                                Copyright &copy; {new Date().getFullYear()} Showqace. All rights reserved
                            </div>

                            <div className="mt-2 text-center">
                                By &nbsp;
                                <a  href="https://moonlightech.com" target="_blank" className="no-underline" >
                                    Moonlight Technology
                                </a>
                            </div>


                        </div>
                    </div>



                </div>
            </footer>
        );
    }

}




// export default withRouter(Footer);