import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { formatMoney, getUserProfileUrl, getUserStoreUrl, shorten } from "../../lib/common-helper";
import { ProductCategory } from '../../models/product-category';
import { PublicUserResponse } from "../../models/response/public-user.response";
import { StoreCategory } from '../../models/store-category';
import { Product } from '../../models/product';
import { ShowqaceCategory } from '../../models/showqace-category';

export function ScrollShowqaceCategory({ itemId, showqaceCategory, fireClickAction }:
  { itemId: string, showqaceCategory: ShowqaceCategory, fireClickAction: any }) {
  const visibility = React.useContext(VisibilityContext);

  const visible = visibility.isItemVisible(itemId);

  // function goToStorePage()
  // {
  //   window.location.href = getUserStoreUrl(publicUserResponse.username, productCategory.id);
  //   return;
  // }

  return (
    <div
      role="button"
      onClick={() => fireClickAction()}
      className="text-center mt-3"
      style={{
        maxWidth: "150px",
        // border: "1px solid",
        display: "inline-block",
        margin: "0 6px",
        // width: "160px",
        userSelect: "none",
      }}
      tabIndex={0}
    >
      <div className="mx-auto me-2 badge pt-2 pb-2 primary-font-color text-truncate" 
        style={{ width: "150px",backgroundColor: "#fff", border: "solid #ccc 1px", borderRadius: "15px" }}>
        {/* <img src={showqaceCategory?.imageFullURL} width={200} height={150} className="p-1" style={{ objectFit: "cover", borderRadius: "12px" }} /> */}
        {shorten(showqaceCategory.name, 30)}
      </div>


    </div>
  );
}
